import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProjectVm } from "src/Api/Models/Project"

export interface IOrganizationProjectsState {
    projects: ProjectVm[]
}

const initialState: IOrganizationProjectsState = {
    projects: [],
}

export const organizationProjectsSlice = createSlice({
    name: "chosenOrganizationProjects",
    initialState,
    reducers: {
        setChosenOrganizationProjects: (
            state,
            action: PayloadAction<ProjectVm[]>
        ) => {
            state.projects = action.payload
        },
    },
})

export const { setChosenOrganizationProjects } =
    organizationProjectsSlice.actions
export default organizationProjectsSlice.reducer
