import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom"
import { PrivateRoute } from "./Pages/PrivateRoute/PrivateRoute"
import { RedirectProvider } from "./Context/RedirectContext"
import useAuth0 from "./Auth/use-auth0"
import SuccessfulVerification from "./Pages/SuccessfulVerificationPage/SuccessfulVerificationPage"
import enGb from "date-fns/locale/en-GB"
import { registerLocale } from "react-datepicker"
import { enGbLocale } from "./Constants/Locale"
import FailureToast from "./Components/Common/Toasts/FailureToast/FailureToast"
import axios from "axios"
import { Suspense, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { getUserId } from "./Api/FetchWrapper/UserClient"
import { RootState } from "./Redux/store"
import { useSelector } from "react-redux"
import { updateCurrentUserId } from "./Redux/Reducers/CurrentUserReducer"
import { useAppDispatch } from "./Redux/hooks"
import React from "react"
import { Configuration, DefaultConfig } from "./Api"
import { baseUrl } from "./config"

registerLocale(enGbLocale, enGb)

enum DeviceType {
    Tablet = "tablet",
    Mobile = "mobile",
    Desktop = "desktop",
}

export const App = () => {
    const NotFound = React.lazy(
        () => import("./Pages/NotFoundPage/NotFoundPage")
    )
    const LoginPage = React.lazy(() => import("./Pages/LoginPage/LoginPage"))
    const RedirectToOrganizationPage = React.lazy(
        () =>
            import(
                "./Pages/RedirectToOrganizationPage.tsx/RedirectToOrganizationPage"
            )
    )
    const NoOrganizationLandingPage = React.lazy(
        () =>
            import(
                "./Components/LandingPage/NoOrganizationLandingComponent/NoOrganizationLandingComponent"
            )
    )
    const JoinOrganizationHandler = React.lazy(
        () =>
            import(
                "./Components/JoinOrganizationHandler/JoinOrganizationHandler"
            )
    )
    const CreateOrganisation = React.lazy(
        () => import("./Components/CreateOrganization/CreateOrganisation")
    )
    const JoinOrganization = React.lazy(
        () => import("./Components/JoinOrganization/JoinOrganization")
    )
    const NoAccessPage = React.lazy(
        () => import("./Pages/ProtectedPages/NoAccessPage/NoAccessPage")
    )
    const OrganizationPage = React.lazy(
        () => import("./Pages/ProtectedPages/OrganizationPage/OrganizationPage")
    )
    const Mobile = React.lazy(() => import("./Pages/MobilePage/MobilePage"))

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
    const dispatch = useAppDispatch()
    const currentUserId = useSelector(
        (rootState: RootState) => rootState.currentUser.currentUserId
    )
    const [isAccessTokenSet, setIsAccessTokenSet] = useState<boolean>(false)
    const getAccessTokenAndSetAxiosInterceptors = async () => {
        const accessToken = await getAccessTokenSilently()
        if (accessToken !== "") {
            setIsAccessTokenSet(true)
            setAxiosInterceptor(accessToken)
        }
    }

    useEffect(() => {
        DefaultConfig.config = new Configuration({
            basePath: baseUrl,
            headers: {},
            middleware: [
                {
                    pre: async (ctx) => {
                        let token: string | null = null

                        token = isAuthenticated
                            ? await getAccessTokenSilently()
                            : null

                        const headers = new Headers(ctx.init.headers)
                        if (token) {
                            headers.set("Authorization", `Bearer ${token}`)
                        }

                        ctx.init.headers = headers

                        return ctx
                    },
                },
            ],
        })
    }, [])

    const setAxiosInterceptor = (accessToken: string) => {
        axios.interceptors.request.use(
            (config) => {
                if (config && config.headers) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`
                }
                return config
            },
            () => {
                FailureToast("Incorporating token went wrong!", toast)
            }
        )
    }

    const deviceType = () => {
        const ua = navigator.userAgent
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return DeviceType.Tablet
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return DeviceType.Mobile
        }
        return DeviceType.Desktop
    }

    const handleCurrentUserId = async () => {
        if (!currentUserId || currentUserId === 0) {
            const newUserId = await getUserId()
            dispatch(updateCurrentUserId(newUserId))
        }
    }

    useEffect(() => {
        if (!isAccessTokenSet && !isLoading && isAuthenticated) {
            getAccessTokenAndSetAxiosInterceptors()
        }
    }, [isAccessTokenSet, isLoading, isAuthenticated])

    useEffect(() => {
        if (isAccessTokenSet) {
            handleCurrentUserId()
        }
    }, [currentUserId, isAccessTokenSet])

    if (isLoading) {
        return <div />
    } else {
        return (
            <RedirectProvider>
                <Router>
                    <Suspense fallback={<div />}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => {
                                    // if (deviceType() === DeviceType.Mobile) {
                                    //   return <Redirect to="/mobile" />;
                                    // } else {
                                    return (
                                        <Redirect to="/organization-redirect" />
                                    )
                                    // }
                                }}
                            />
                            <Route
                                path="/login-page"
                                component={() => <LoginPage />}
                            />
                            <Route
                                path="/not-found"
                                component={() => <NotFound />}
                            />
                            <Route
                                path="/mobile"
                                component={() => <Mobile />}
                            />
                            <Route
                                path="/successful-verification"
                                component={() => <SuccessfulVerification />}
                            />
                            <Route
                                path="/no-access"
                                component={() => <NoAccessPage />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/organization/:id"
                                component={() => <OrganizationPage />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/join-organization/:inviteCode"
                                component={() => <JoinOrganizationHandler />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/join-organization"
                                component={() => <JoinOrganization />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/no-organization"
                                component={() => <NoOrganizationLandingPage />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/create-organization"
                                component={() => <CreateOrganisation />}
                            />
                            <PrivateRoute
                                isAuthenticated={isAuthenticated}
                                authenticationPath="/login-page"
                                isAccessTokenSet={isAccessTokenSet}
                                path="/organization-redirect"
                                component={() => <RedirectToOrganizationPage />}
                            />
                            <Route
                                render={() => <Redirect to="/not-found" />}
                            />
                        </Switch>
                    </Suspense>
                </Router>
            </RedirectProvider>
        )
    }
}
export default App
