import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TeamMemberDto } from "src/Api/Models/TeamMemberVm"

export interface IOrganizationUsersState {
    projectUsers: TeamMemberDto[]
}

const initialState: IOrganizationUsersState = {
    projectUsers: [],
}

export const projectUsersSlice = createSlice({
    name: "projectUsers",
    initialState,
    reducers: {
        setChosenProjectUsers: (
            state,
            action: PayloadAction<TeamMemberDto[]>
        ) => {
            state.projectUsers = action.payload
        },
    },
})

export const { setChosenProjectUsers } = projectUsersSlice.actions
export default projectUsersSlice.reducer
