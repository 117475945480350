import testingApi from "./testsApiConfig.json"

export const auth = {
    domain: "login.onesoft.no",
    audience: "https://api.onesoft.no",
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
        ? process.env.REACT_APP_AUTH0_CLIENT_ID
        : "1Z6BqaeLl1iVxN2i8NLOdyrqtGVeBpxp",
    responseType: "token id_token",
    scope: "openid email profile",
}

export const baseUrl =
    testingApi.testingApiBaseUrl !== ""
        ? testingApi.testingApiBaseUrl
        : process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : "https://oneplatformapi.azurewebsites.net"
