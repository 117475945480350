import { User } from "@auth0/auth0-spa-js"

export interface AuthState<TUser extends User = User> {
    error?: Error
    isAuthenticated: boolean
    isLoading: boolean
    user?: TUser
}

export const initialAuthState: AuthState = {
    isAuthenticated: false,
    isLoading: true,
}
