import MediaQuery from "react-responsive"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useRedirect } from "../../Context/RedirectContext"

export type ProtectedRouteProps = {
    isAuthenticated: boolean
    authenticationPath: string
    isAccessTokenSet: boolean
} & RouteProps

export const PrivateRoute = ({
    isAuthenticated,
    authenticationPath,
    isAccessTokenSet,
    ...routeProps
}: ProtectedRouteProps) => {
    const { setUrl } = useRedirect()

    const checkIfRouteIsOnMobileDevice = (component: JSX.Element) => {
        return (
            <>
                <MediaQuery minDeviceWidth={764}>{component}</MediaQuery>
                <MediaQuery maxDeviceWidth={764}>
                    <Redirect to="/mobile" />
                </MediaQuery>
            </>
        )
    }
    if (isAuthenticated) {
        if (isAccessTokenSet) {
            return checkIfRouteIsOnMobileDevice(<Route {...routeProps} />)
        }
        return <div />
    } else {
        setUrl(routeProps.location?.pathname as string)
        return <Redirect to={{ pathname: authenticationPath }} />
    }
}
