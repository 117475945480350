/* eslint-disable @typescript-eslint/ban-ts-comment */
import ReactDOM from "react-dom"
import "./Styles/Body.scss"
import reportWebVitals from "./reportWebVitals"
import "./i18n"
import App from "./App"
import * as data from "./config"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import Auth0Provider from "./Auth/auth0-provider"
import { Toaster } from "react-hot-toast"
import { OrganizationProvider } from "./Context/OrganizationContext"
import { Provider } from "react-redux"
import { persistor, store } from "./Redux/store"
import { PersistGate } from "redux-persist/integration/react"

import "./Styles/index.css"

ReactDOM.render(
    <Auth0Provider
        domain={data.auth.domain}
        clientId={data.auth.clientID}
        redirectUri={window.location.origin}
        audience={data.auth.audience}
        scope={data.auth.scope}
        cacheLocation="localstorage"
    >
        <Toaster />
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <OrganizationProvider>
                        <App />
                    </OrganizationProvider>
                </PersistGate>
            </Provider>
        </DndProvider>
    </Auth0Provider>,
    document.getElementById("root")
)

// @ts-ignore
if (window.Cypress) {
    // @ts-ignore
    window.store = store
}

reportWebVitals()
