import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ProjectVm } from "src/Api/Models/Project"
import { IMappedCustomerVm } from "src/Components/ProjectOverviewMain/ProjectOverviewMain"

export interface IProjectState {
    project: ProjectVm | null
    projectCustomer: IMappedCustomerVm | null
}

const initialState: IProjectState = {
    project: null,
    projectCustomer: null,
}

export const projectSlice = createSlice({
    name: "chosenProject",
    initialState,
    reducers: {
        updateChosenProject: (state, action: PayloadAction<ProjectVm>) => {
            state.project = action.payload
        },
        updateProjectCustomer: (
            state,
            action: PayloadAction<IMappedCustomerVm | null>
        ) => {
            state.projectCustomer = action.payload
        },
        updateAddingProjectUsersAutomatization: (
            state,
            action: PayloadAction<boolean>
        ) => {
            if (state.project) {
                state.project.addNewOrganizationUsersAutomatically =
                    action.payload
            }
        },
        resetChosenProject: (state) => {
            state.project = null
        },
    },
})

export const {
    updateChosenProject,
    resetChosenProject,
    updateAddingProjectUsersAutomatization,
    updateProjectCustomer,
} = projectSlice.actions
export default projectSlice.reducer
