/* eslint-disable prettier/prettier */
import { Client } from "src/Api/Client/ApiClient"
import { UserProfileVm } from "src/Api/Models/UserProfileVm"
import { UserWithInvitationDto } from "../Models/UserWithInvitationDto"
import * as data from "src/config"
import { UpdateUserInfoRequest } from "../Requests/UserRequestsInterfaces/UpdateUserInfoRequest"
import { CreateGuestRequestDto } from "../Requests/CreateGuestRequestDto"
import { DetailedUserDto } from "../Models/DetailedUserDto"
import { CurrentUserDetails } from "../Models/CurrentUserDetails"

const users = "Users"
const base = data.baseUrl

const getUserId = async (): Promise<number> => {
    return Client("GET", `${users}/UserId`, base)
}

const getUserDetails = async (
    organizationId: number
): Promise<CurrentUserDetails> => {
    return Client(
        "GET",
        `${users}/UserDetails/Organization/${organizationId}`,
        base
    )
}

const getUserProjectRole = async (
    organizationId: number,
    projectId: number
): Promise<number> => {
    return Client(
        "GET",
        `${users}/Organization/${organizationId}/Project/${projectId}`,
        base
    )
}

const getUsersInOrganizationWithInvitation = async (
    organizationId: number
): Promise<UserWithInvitationDto[]> => {
    return Client(
        "GET",
        `${users}/organization/${organizationId}/withInvitation`,
        base
    )
}

const getUserCredentialsByUserId = async (): Promise<UserProfileVm> => {
    return Client("GET", `${users}/user/credentials`, base)
}

const updateUserCredentials = async (
    userName: string,
    userSurname: string
): Promise<UserProfileVm> => {
    return Client("POST", `${users}/${userName}/${userSurname}`, base)
}

const updateUserInfo = async (
    userId: number,
    body: UpdateUserInfoRequest
): Promise<null> => {
    return Client("PUT", `${users}/${userId}`, base, { body })
}

const createGuest = async (body: CreateGuestRequestDto): Promise<null> => {
    return Client("POST", `${users}/guest`, base, { body })
}

const checkIfUserExist = async (email: string): Promise<DetailedUserDto> => {
    return Client("GET", `${users}/isExist/${email}`, base)
}

const getApiKey = async (): Promise<string> => {
    return Client("GET", `export/ApiKey`, base)
}

const refreshApiKey = async (): Promise<string> => {
    return Client("POST", `export/ApiKey`, base)
}

const deleteApiKey = async (): Promise<string> => {
    return Client("DELETE", `export/ApiKey`, base)
}

export {
    getUserId,
    getUsersInOrganizationWithInvitation,
    getUserCredentialsByUserId,
    updateUserCredentials,
    updateUserInfo,
    createGuest,
    checkIfUserExist,
    getUserDetails,
    getUserProjectRole,
    getApiKey,
    refreshApiKey,
    deleteApiKey,
}
