import "./SuccessfulVerificationPage.scss"
import OneIcon from "../../Images/Color-logo-new-version.png"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import Loader from "src/Components/Common/Loader/SmallerLoader"
import { FullMailLink } from "src/Constants/HelpMailParameters"
import { useTranslation } from "react-i18next"

const SuccessfulVerification = () => {
    const [redirectUrl, setRedirectUrl] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const history = useHistory()

    useEffect(() => {
        setRedirectUrl("/login-page")
        setIsLoading(false)
    }, [])

    const redirectToPage = () => {
        if (redirectUrl !== undefined) {
            history.push(redirectUrl)
        }
    }

    const { t } = useTranslation()

    if (redirectUrl === undefined && isLoading) {
        return <Loader />
    } else {
        return (
            <div className="successful-verification-page">
                <div className="wrapper">
                    <div className="content">
                        <img
                            src={OneIcon}
                            alt="One icon"
                            className="one-icon"
                        />
                        <span className="title-text">
                            Successful verification
                        </span>
                        <span className="subtitle-text">
                            {
                                "You can now log into your account and use One's features"
                            }
                        </span>
                        <button
                            className="log-in-button log-in-button-text"
                            onClick={() => redirectToPage()}
                        >
                            {t("page.login")}
                        </button>
                        <div className="help-link">
                            <a href={FullMailLink}>{t("login.problem")}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SuccessfulVerification
