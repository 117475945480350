import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { OrganizationUserDto } from "src/Api/Models/OrganizationUserDto"

export interface IOrganizationUsersState {
    organizationUsers: OrganizationUserDto[]
}

const initialState: IOrganizationUsersState = {
    organizationUsers: [],
}

export const organizationUsersSlice = createSlice({
    name: "chosenOrganizationUsers",
    initialState,
    reducers: {
        setChosenOrganizationUsers: (
            state,
            action: PayloadAction<OrganizationUserDto[]>
        ) => {
            state.organizationUsers = action.payload
        },
    },
})

export const { setChosenOrganizationUsers } = organizationUsersSlice.actions
export default organizationUsersSlice.reducer
