import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PageViewMode } from "src/Pages/ProtectedPages/OrganizationPage/OrganizationPage"

export interface IPageViewMode {
    currentMode: PageViewMode | undefined
}

const initialState: IPageViewMode = {
    currentMode: undefined,
}

export const currentViewModeSlice = createSlice({
    name: "currentViewMode",
    initialState,
    reducers: {
        updateCurrentViewMode: (state, action: PayloadAction<PageViewMode>) => {
            state.currentMode = action.payload
        },
    },
})

export const { updateCurrentViewMode } = currentViewModeSlice.actions
export default currentViewModeSlice.reducer
