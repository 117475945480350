import * as React from "react"
import { OrganizationDto } from "src/Api/Models/OrganizationDto"

interface IOrganizationContext {
    currentOrganization: OrganizationDto | undefined
    setCurrentOrganization: (organization: OrganizationDto) => void
}
const defaultState: IOrganizationContext = {
    currentOrganization: undefined,
    setCurrentOrganization: () => {
        return
    },
}

const OrganizationContext =
    React.createContext<IOrganizationContext>(defaultState)

type Props = {
    children: React.ReactNode
}

export const OrganizationProvider = ({ children }: Props) => {
    const [organization, setOrganization] = React.useState<
        OrganizationDto | undefined
    >(undefined)

    const value: IOrganizationContext = {
        currentOrganization: organization,
        setCurrentOrganization: setOrganization,
    }
    return (
        <OrganizationContext.Provider value={value}>
            {children}
        </OrganizationContext.Provider>
    )
}

export const useOrganization = () => {
    const context = React.useContext(OrganizationContext)
    if (context === undefined) {
        throw new Error(
            "useOrganization must be used within a RedirectProvider"
        )
    }
    return context
}
