import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from "./Translations/en.json"
import no from "./Translations/no.json"

const resources = {
    en: {
        translation: en,
    },
    no: {
        translation: no,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "en",
    lng: localStorage.getItem("language") ?? undefined,
})

export default i18n
