import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DownloadedFile } from "src/common-components/components/Files/Interfaces/DownloadedFile"
import { OrganizationWithRoleDto } from "src/Api/Models/OrganizationWithRoleId"
import { ModifyOrganizationRequest } from "src/Api/Requests/OrganizationRequestsInterfaces/ModifyOrganizationRequest"

export interface IOrganizationState {
    organization: OrganizationWithRoleDto | null
    organizationLogo: DownloadedFile | null
}

const initialState: IOrganizationState = {
    organization: null,
    organizationLogo: null,
}

export const organizationSlice = createSlice({
    name: "chosenOrganization",
    initialState,
    reducers: {
        updateChosenOrganization: (
            state,
            action: PayloadAction<OrganizationWithRoleDto>
        ) => {
            state.organization = action.payload
        },
        resetChosenOrganization: (state) => {
            state.organization = null
        },
        updateOrganizationLogo: (
            state,
            action: PayloadAction<DownloadedFile>
        ) => {
            state.organizationLogo = action.payload
        },
        updateChosenOrganizationProperties: (
            state,
            action: PayloadAction<ModifyOrganizationRequest>
        ) => {
            if (state.organization) {
                state.organization = {
                    ...state.organization,
                    ...action.payload,
                }
            }
        },
    },
})

export const {
    updateChosenOrganization,
    updateChosenOrganizationProperties,
    updateOrganizationLogo,
} = organizationSlice.actions
export default organizationSlice.reducer
