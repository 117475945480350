/* eslint-disable no-console */
import axios, { Method } from "axios"

/* eslint-disable @typescript-eslint/no-unused-vars */
const Client = async (
    method: Method,
    endpoint: string,
    applicationBase: string,
    { body, params, responseIsBlob = false, ...customConfig }: any = {}
): Promise<any> => {
    try {
        const requestResult = await axios({
            method: method,
            url: `${applicationBase}/${endpoint}`,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            data: JSON.stringify(body),
            params: params,
            responseType: responseIsBlob ? "blob" : "json",
        })
        return requestResult.data
    } catch (error: any) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("Request error", error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message)
        }
        throw error
    }
}
export { Client }
