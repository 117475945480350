// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useReducer, useState } from "react"
import {
    Auth0Client,
    Auth0ClientOptions,
    CacheLocation,
    IdToken,
    LogoutOptions,
    LogoutUrlOptions,
    PopupLoginOptions,
    PopupConfigOptions,
    RedirectLoginOptions as Auth0RedirectLoginOptions,
    GetTokenWithPopupOptions,
    GetTokenSilentlyOptions,
    GetIdTokenClaimsOptions,
    RedirectLoginResult,
} from "@auth0/auth0-spa-js"
import Auth0Context, { RedirectLoginOptions } from "./auth0-context"
import { hasAuthParams, loginError, tokenError } from "./utils"
import { reducer } from "./reducer"
import { initialAuthState } from "./auth-state"

export type AppState = {
    returnTo?: string
    [key: string]: any
}

export interface Auth0ProviderOptions {
    children?: React.ReactNode
    onRedirectCallback?: (appState: AppState) => void
    skipRedirectCallback?: boolean
    domain: string
    issuer?: string
    clientId: string
    redirectUri?: string
    leeway?: number
    cacheLocation?: CacheLocation
    useRefreshTokens?: boolean
    authorizeTimeoutInSeconds?: number
    advancedOptions?: {
        defaultScope?: string
    }
    maxAge?: string | number
    scope?: string
    audience?: string
    organization?: string
    invitation?: string
    [key: string]: any
}

const toAuth0ClientOptions = (
    opts: Auth0ProviderOptions
): Auth0ClientOptions => {
    const { clientId, redirectUri, maxAge, ...validOpts } = opts
    return {
        ...validOpts,
        client_id: clientId,
        redirect_uri: redirectUri,
        max_age: maxAge,
        auth0Client: {
            name: "auth0-react",
            version: "1",
        },
    }
}

const toAuth0LoginRedirectOptions = (
    opts?: RedirectLoginOptions
): Auth0RedirectLoginOptions | undefined => {
    if (!opts) {
        return
    }
    const { redirectUri, ...validOpts } = opts
    return {
        ...validOpts,
        redirect_uri: redirectUri,
    }
}

const defaultOnRedirectCallback = (appState?: AppState): void => {
    window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname
    )
}

const Auth0Provider = (opts: Auth0ProviderOptions): JSX.Element => {
    const {
        children,
        skipRedirectCallback,
        onRedirectCallback = defaultOnRedirectCallback,
        ...clientOpts
    } = opts
    const [client] = useState(
        () => new Auth0Client(toAuth0ClientOptions(clientOpts))
    )
    const [state, dispatch] = useReducer(reducer, initialAuthState)

    useEffect(() => {
        ;(async (): Promise<void> => {
            try {
                if (hasAuthParams() && !skipRedirectCallback) {
                    const { appState } = await client.handleRedirectCallback()
                    onRedirectCallback(appState)
                } else {
                    await client.checkSession()
                }
                const user = await client.getUser()
                dispatch({ type: "INITIALISED", user })
            } catch (error) {
                // dispatch({ type: "ERROR", error: loginError(error) });
            }
        })()
    }, [client, onRedirectCallback, skipRedirectCallback])

    const buildAuthorizeUrl = useCallback(
        (opts?: RedirectLoginOptions): Promise<string> =>
            client.buildAuthorizeUrl(toAuth0LoginRedirectOptions(opts)),
        [client]
    )

    const buildLogoutUrl = useCallback(
        (opts?: LogoutUrlOptions): string => client.buildLogoutUrl(opts),
        [client]
    )

    const loginWithRedirect = useCallback(
        (opts?: RedirectLoginOptions): Promise<void> =>
            client.loginWithRedirect(toAuth0LoginRedirectOptions(opts)),
        [client]
    )

    const loginWithPopup = useCallback(
        async (
            options?: PopupLoginOptions,
            config?: PopupConfigOptions
        ): Promise<void> => {
            dispatch({ type: "LOGIN_POPUP_STARTED" })
            try {
                await client.loginWithPopup(options, config)
            } catch (error) {
                dispatch({ type: "ERROR", error: loginError(error) })
                return
            }
            const user = await client.getUser()
            dispatch({ type: "LOGIN_POPUP_COMPLETE", user })
        },
        [client]
    )

    const logout = useCallback(
        (opts: LogoutOptions = {}): void => {
            client.logout(opts)
            if (opts.localOnly) {
                dispatch({ type: "LOGOUT" })
            }
        },
        [client]
    )

    const getAccessTokenSilently = useCallback(
        async (opts?: GetTokenSilentlyOptions): Promise<string> => {
            let token
            try {
                token = await client.getTokenSilently(opts)
            } catch (error) {
                // throw tokenError(error);
            } finally {
                dispatch({
                    type: "GET_ACCESS_TOKEN_COMPLETE",
                    user: await client.getUser(),
                })
            }
            return token
        },
        [client]
    )

    const getAccessTokenWithPopup = useCallback(
        async (
            opts?: GetTokenWithPopupOptions,
            config?: PopupConfigOptions
        ): Promise<string> => {
            let token
            try {
                token = await client.getTokenWithPopup(opts, config)
            } catch (error) {
                throw tokenError(error)
            } finally {
                dispatch({
                    type: "GET_ACCESS_TOKEN_COMPLETE",
                    user: await client.getUser(),
                })
            }
            return token
        },
        [client]
    )

    const getIdTokenClaims = useCallback(
        (opts?: GetIdTokenClaimsOptions): Promise<IdToken> =>
            client.getIdTokenClaims(opts),
        [client]
    )

    const handleRedirectCallback = useCallback(
        async (url?: string): Promise<RedirectLoginResult> => {
            try {
                return await client.handleRedirectCallback(url)
            } catch (error) {
                throw tokenError(error)
            } finally {
                dispatch({
                    type: "HANDLE_REDIRECT_COMPLETE",
                    user: await client.getUser(),
                })
            }
        },
        [client]
    )

    return (
        <Auth0Context.Provider
            value={{
                ...state,
                buildAuthorizeUrl,
                buildLogoutUrl,
                getAccessTokenSilently,
                getAccessTokenWithPopup,
                getIdTokenClaims,
                loginWithRedirect,
                loginWithPopup,
                logout,
                handleRedirectCallback,
            }}
        >
            {children}
        </Auth0Context.Provider>
    )
}

export default Auth0Provider
