import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { OptionTypeBase } from "react-select"

export interface IProjectWorkers {
    projectWorkers: OptionTypeBase[]
}

const initialState: IProjectWorkers = {
    projectWorkers: [],
}

export const projectWorkersSlice = createSlice({
    name: "chosenProjectWorkers",
    initialState,
    reducers: {
        updateChosenProjectWorkers: (
            state,
            action: PayloadAction<OptionTypeBase[]>
        ) => {
            state.projectWorkers = action.payload
        },
    },
})

export const { updateChosenProjectWorkers } = projectWorkersSlice.actions
export default projectWorkersSlice.reducer
