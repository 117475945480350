import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { PERSIST, persistReducer } from "redux-persist"
import persistStore from "redux-persist/es/persistStore"
import chosenOrganizationReducer from "./Reducers/ChosenOrganizationReducer"
import chosenProjectReducer from "./Reducers/ChosenProjectReducer"
import chosenOrganizationUsersReducer from "./Reducers/ChosenOrganizationUsersReducer"
import chosenProjectWorkersReducer from "./Reducers/ChosenProjectWorkersReducer"
import currentUserReducer from "./Reducers/CurrentUserReducer"
import currentViewModeReducer from "./Reducers/PageViewModes"
import ChosenProjectUsersReducer from "./Reducers/ChosenProjectUsersReducer"
import chosenOrganizationProjectsReducer from "./Reducers/ChosenOrganizationProjectsReducer"
import localforage from "localforage"

const reducers = combineReducers({
    chosenOrganizationProjects: chosenOrganizationProjectsReducer,
    chosenOrganization: chosenOrganizationReducer,
    chosenProject: chosenProjectReducer,
    chosenOrganizationUsers: chosenOrganizationUsersReducer,
    chosenProjectWorkers: chosenProjectWorkersReducer,
    chosenProjectUsers: ChosenProjectUsersReducer,
    currentUser: currentUserReducer,
    currentViewMode: currentViewModeReducer,
})

const persistConfig = {
    key: "root",
    version: 1,
    storage: localforage,
}

//Local forage fixes problem with a bug: https://github.com/rt2zz/redux-persist/issues/870
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [PERSIST],
            },
        }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
