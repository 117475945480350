import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CurrentUserDetails } from "src/Api/Models/CurrentUserDetails"

export interface IProjectState {
    currentUserId: number
    currentUserDetails: CurrentUserDetails | null
}

const initialState: IProjectState = {
    currentUserId: 0,
    currentUserDetails: null,
}

export const currentUserSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        updateCurrentUserId: (state, action: PayloadAction<number>) => {
            state.currentUserId = action.payload
        },
        updateCurrentUserDetails: (
            state,
            action: PayloadAction<CurrentUserDetails>
        ) => {
            state.currentUserDetails = action.payload
        },
        updateCurrentUserProjectRole: (
            state,
            action: PayloadAction<number>
        ) => {
            if (state.currentUserDetails) {
                state.currentUserDetails.projectRole = action.payload
            }
        },
    },
})

export const {
    updateCurrentUserId,
    updateCurrentUserDetails,
    updateCurrentUserProjectRole,
} = currentUserSlice.actions
export default currentUserSlice.reducer
