import "./FailureToast.scss"

const FailureToast = (message: string, toast: any) => {
    toast.error(<div>{message}</div>, {
        style: {},
        className: "failure-toast--text-center",
    })
}

export default FailureToast
