import {
    BaseLoginOptions,
    GetIdTokenClaimsOptions,
    GetTokenSilentlyOptions,
    GetTokenWithPopupOptions,
    IdToken,
    LogoutOptions,
    LogoutUrlOptions,
    PopupLoginOptions,
    PopupConfigOptions,
    RedirectLoginOptions as Auth0RedirectLoginOptions,
    RedirectLoginResult,
    User,
} from "@auth0/auth0-spa-js"
import { createContext } from "react"
import { AuthState, initialAuthState } from "./auth-state"

export interface RedirectLoginOptions extends BaseLoginOptions {
    redirectUri?: string
    appState?: any
    fragment?: string
}

export interface Auth0ContextInterface<TUser extends User = User>
    extends AuthState<TUser> {
    getAccessTokenSilently: (
        options?: GetTokenSilentlyOptions
    ) => Promise<string>
    getAccessTokenWithPopup: (
        options?: GetTokenWithPopupOptions,
        config?: PopupConfigOptions
    ) => Promise<string>
    getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<IdToken>
    loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
    loginWithPopup: (
        options?: PopupLoginOptions,
        config?: PopupConfigOptions
    ) => Promise<void>
    logout: (options?: LogoutOptions) => void
    buildAuthorizeUrl: (options?: Auth0RedirectLoginOptions) => Promise<string>
    buildLogoutUrl: (options?: LogoutUrlOptions) => string
    handleRedirectCallback: (url?: string) => Promise<RedirectLoginResult>
}

const stub = (): never => {
    throw new Error("You forgot to wrap your component in <Auth0Provider>.")
}

const initialContext = {
    ...initialAuthState,
    buildAuthorizeUrl: stub,
    buildLogoutUrl: stub,
    getAccessTokenSilently: stub,
    getAccessTokenWithPopup: stub,
    getIdTokenClaims: stub,
    loginWithRedirect: stub,
    loginWithPopup: stub,
    logout: stub,
    handleRedirectCallback: stub,
}

const Auth0Context = createContext<Auth0ContextInterface>(initialContext)

export default Auth0Context
