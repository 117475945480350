/* tslint:disable */
/* eslint-disable */
/**
 * OneWeb.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateNewUserDto,
  OrganizationWithRoleDto,
} from '../models';
import {
    CreateNewUserDtoFromJSON,
    CreateNewUserDtoToJSON,
    OrganizationWithRoleDtoFromJSON,
    OrganizationWithRoleDtoToJSON,
} from '../models';

export interface Auth0M2MCreateAndAddPostRequest {
    createNewUserDto?: CreateNewUserDto;
}

/**
 * 
 */
export class Auth0M2MApi extends runtime.BaseAPI {

    /**
     */
    async auth0M2MCreateAndAddPostRaw(requestParameters: Auth0M2MCreateAndAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationWithRoleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

        const response = await this.request({
            path: `/Auth0M2M/CreateAndAdd`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNewUserDtoToJSON(requestParameters.createNewUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationWithRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async auth0M2MCreateAndAddPost(requestParameters: Auth0M2MCreateAndAddPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationWithRoleDto> {
        const response = await this.auth0M2MCreateAndAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
