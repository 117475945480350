import ClipLoader from "react-spinners/ClipLoader"
import "./SmallerLoader.scss"

interface LoaderProps {
    color: string
    size: number
}

const SmallerLoader = ({ color, size }: LoaderProps) => {
    return (
        <div className="smaller-loader__container" data-cy="Small-loader">
            <ClipLoader color={color} loading={true} size={size} />
        </div>
    )
}
SmallerLoader.defaultProps = {
    color: "#ca333d",
    size: 100,
}

export default SmallerLoader
