import * as React from "react"
import { useLocalStorage } from "../Hooks/UseLocalStorage"

const RedirectContext = React.createContext<
    { url: string; setUrl: (url: string) => void } | undefined
>(undefined)

type Props = {
    children: React.ReactNode
}
export const RedirectProvider = ({ children }: Props) => {
    const [url, setUrl] = useLocalStorage<string>("redirectUrl", "....")

    const value = { url, setUrl }
    return (
        <RedirectContext.Provider value={value}>
            {children}
        </RedirectContext.Provider>
    )
}

export const useRedirect = () => {
    const context = React.useContext(RedirectContext)
    if (context === undefined) {
        throw new Error("useRedirect must be used within a RedirectProvider")
    }
    return context
}
